<template>
  <b-card no-body>
    <b-card-body v-if="displayTitle">
      <b-card-title v-if="editableName">
        <b-row>
          <!-- Translated object for operator (parameter), else simply string -->
          <b-col v-if="typeof workrole === 'object' && workrole" lg="6" xl="5">
            <app-form-text-input-itn
              v-if="typeof workrole === 'object' && workrole"
              v-model="workrole"
              :label="$t('user.workrole')"
              :languages="$store.state.auth.activelang"
              form-name="user-workrole"
            />
          </b-col>
          <b-col v-else md="4">
            <app-input
              id="workroleName"
              :label="$t('user.workrole')"
              :placeholder="$t('user.workrole')"
              :value="workrole"
              @input="$emit('update:workrole', $event)"
            />
          </b-col>
        </b-row>
      </b-card-title>

      <b-card-title v-else>
        <h4 class="text-center">{{ userData.workrole }}</h4>
      </b-card-title>
    </b-card-body>

    <app-data-table
      :busy="!userData.rights"
      :fields="fields"
      :hover="false"
      :items="localRights"
      :small="$store.getters['app/mdAndDown']"
      all-items
      sticky-header
      striped
      table-class="mb-0 rights-table-content"
      table-name="user-rights"
    >
      <template v-for="field in fields" :slot="`cell(${field.key})`" slot-scope="{ item }">
        <div :key="field.key" class="d-flex justify-content-center">
          <b-form-checkbox
            :checked="item[field.key]"
            :disabled="!editableRights || !grantableRights.includes(toUpper(`${item.name}_${field.key}`))"
            style="cursor: pointer"
            @change="updateRights(toUpper(`${item.name}_${field.key}`), $event)"
          />
        </div>
      </template>

      <template #cell(name)="{ item }">
        {{ $t(`user.right.features.${item.name}`) }}
      </template>
    </app-data-table>
  </b-card>
</template>

<script>
import { defineComponent, ref, watch } from '@vue/composition-api'
import { snakeCase, toUpper, uniq } from 'lodash'

import AppDataTable from '@/components/AppDataTable.vue'

export default defineComponent({
  name: 'RightsTable',

  components: {
    AppDataTable,
  },

  props: {
    isMe: {
      type: Boolean,
      default: false,
    },
    grantableRights: {
      type: Array,
      default: () => [],
    },
    workrole: {
      type: [Object, String],
      default: null,
    },
    userData: {
      type: Object,
      default: () => ({ id: null }),
    },
    editableName: {
      type: Boolean,
      default: true,
    },
    editableRights: {
      type: Boolean,
      default: true,
    },
    displayTitle: {
      type: Boolean,
      default: true,
    },
  },
  setup(props, ctx) {
    const { $i18n, $can, _cloneDeep } = ctx.root
    const $emit = ctx.emit

    const fields = ref([{ key: 'name', label: $i18n.t('user.right.name'), tdClass: 'w-100' }])
    const localRights = ref([])
    const defaultLanguage = ref(localStorage.getItem('appLang'))

    watch([() => props.userData, () => props.grantableRights], () => {
      // Future pattern: { the_feature: { action: <bool>, ... }, ... }
      const rights = {}

      // Merge his and my rights to display all his rights and all my possibilities to update its.
      // If userData is me or operator, so `props.grantableRights === props.userData.rights` (or include), and merge is useless.
      const mergeRights = props.isMe || $can('OPERATOR_PARAMETER_VIEW') ? props.grantableRights : uniq(props.grantableRights.concat(props.userData.rights))
      mergeRights.forEach(right => {
        // Pattern: THE_FEATURE_ACTION => `the_feature` and `action`. Useful also for translations.
        const feature = snakeCase(right.match(/(.*)_/)[1])
        const action = snakeCase(right.match(/.*_(.*)/)[1])

        // Get all differents types actions to fill `fields` prop
        if (!fields.value.find(field => field.key === action)) {
          fields.value.push({
            key: action,
            label: $i18n.t(`user.right.${action}`),
          })
        }

        // If `userData` is me, it's useless to check the includes()
        const isGranted = props.isMe || props.userData.rights.includes(right)

        if (rights[feature]) {
          rights[feature][action] = isGranted
        } else {
          rights[feature] = { name: feature, [action]: isGranted }
        }
      })

      // The `fields` will be sorted along `orderActions`
      const orderActions = ['view', 'add', 'edit', 'delete']
      fields.value = fields.value.sort((firstAction, secondAction) => {
        const firstActionIndex = orderActions.findIndex(action => action === firstAction.key)

        // If `firstAction.key` is not include in `orderActions`, keep his position
        if (firstActionIndex === -1) return 0
        const secondActionIndex = orderActions.findIndex(action => action === secondAction.key)
        return firstActionIndex - secondActionIndex
      })

      // `rights` becomes `[{ the_feature: { action: <bool>, ...} }, ...]` - valid format to b-table.
      localRights.value = Object.values(rights)

      // TODO: immediate true is not a good idea, because for FO, the code runs twice
    }, { deep: true, immediate: true })

    // Fill `rightsToAdd` or `rightsToDelete` about update rights and his old rights
    const updateRights = (right, isAdd) => {
      let rightsToAdd = _cloneDeep(props.userData.rightsToAdd)
      let rightsToDelete = _cloneDeep(props.userData.rightsToDelete)
      if (isAdd) {
        if (rightsToDelete.includes(right)) {
          rightsToDelete = rightsToDelete.filter(rightToDelete => rightToDelete !== right)
        } else {
          rightsToAdd.push(right)
        }
      } else if (rightsToAdd.includes(right)) {
        rightsToAdd = rightsToAdd.filter(rightToAdd => rightToAdd !== right)
      } else {
        rightsToDelete.push(right)
      }
      $emit('update:rights', { rightsToAdd, rightsToDelete })
    }

    return {
      defaultLanguage,
      fields,
      localRights,
      updateRights,
      toUpper,
    }
  },
})
</script>

<style>
.rights-table-content {
  max-height: 70vh;
}
</style>
