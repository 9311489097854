<template>
  <section>
    <!--HEADER-->
    <div class="d-flex justify-content-between">
      <h4 class="d-inline-flex">{{ $t('organization.airports') }}</h4>
      <app-btn-add :disabled="!airportOptionsFiltered.length" @click="repeatAgain" />
    </div>
    <div class="mb-1">{{ $t('service.location.introduction') }}</div>

    <!--LIST-->
    <b-overlay :show="loadOrganizationAirports" no-fade>
      <div v-if="!airportOptions.length">
        <b-row>
          <b-col cols="12">
            <b-alert variant="primary" class="m-0" show>
              <div class="alert-body">
                <span>{{ $t('alert.airport.no_airport_found_for_organization') }}</span>
              </div>
            </b-alert>
          </b-col>
        </b-row>
      </div>

      <b-card v-else class="border airports-rows mb-0">
        <!-- Row Loop -->
        <b-row
          v-for="(organizationAirport, index) in localOrganizationUserAirports"
          :key="index"
          ref="rowLocation"
          class="d-flex align-items-center pt-50"
          :class="index === localOrganizationUserAirports.length - 1 ? '' : 'border-bottom'"
        >
          <b-col md="9" xl="" class="mb-sm-50">
            <v-select
              v-model="localOrganizationUserAirports[index]"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="airportOptionsFiltered"
              :append-to-body="true"
              :clearable="false"
              label="name"
            />
          </b-col>

          <b-col md="3" xl="" class="d-flex justify-content-between align-items-center mt-25 mt-sm-0 mb-sm-50">
            <b-form-checkbox
              v-model="localOrganizationUserAirports[index].active"
              :checked="localOrganizationUserAirports[index].active"
              :disabled="airportSwitchActiveDisabled"
              name="check-button"
              switch
              inline
            >
              {{ $t('common.active') }}
            </b-form-checkbox>

            <!-- Remove/add Button -->
            <b-button-group>
              <b-button v-ripple.400 :size="$store.getters['app/mdAndDown'] ? 'sm' : 'md'" variant="outline-primary" @click="confirmDeleteAlert(index)">
                <font-awesome-icon v-b-tooltip.hover.bottom.v-danger icon="trash" class="text-danger" :title="$t('action.delete')" />
              </b-button>
            </b-button-group>
          </b-col>
        </b-row>
      </b-card>
    </b-overlay>
  </section>
</template>

<script>
import 'vue-form-wizard/dist/vue-form-wizard.min.css'

import { fetchOrganizationAirportsRequest } from '@/request/globalApi/requests/organizationRequests'
import AppBtnAdd from '@/components/AppBtnAdd.vue'

export default {
  name: 'AirportOrganization',

  components: {
    AppBtnAdd,
  },

  props: {
    value: {
      type: Array,
      default: () => [],
    },
    airportSwitchActiveDisabled: {
      type: Boolean,
      default: false,
    },
    selectedOrganization: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      loadOrganizationAirports: false,
      airportOptions: [],
      localOrganizationUserAirports: [],
    }
  },
  computed: {
    airportOptionsFiltered() {
      const airportIdSelected = this.localOrganizationUserAirports.map(organizationAirport => organizationAirport.id)
      return this.airportOptions.filter(airport => !airportIdSelected.includes(airport.id))
    },
  },
  watch: {
    localOrganizationUserAirports: {
      handler() {
        const airportsIds = this.localOrganizationUserAirports.map(airport => airport.id)
        this.$emit('input', airportsIds)
      },
      deep: true,
    },
  },
  mounted() {
    this.APIFetchOrganizationAirports()
  },
  methods: {
    APIFetchOrganizationAirports() {
      this.loadOrganizationAirports = true
      const objectifyOrganizationUserAirports = []
      fetchOrganizationAirportsRequest(this.selectedOrganization.id, { getAllItems: true }).then(response => {
        this.loadOrganizationAirports = false
        const { organizationAirports } = response.data

        organizationAirports.forEach(el => {
          const airport = { ...el.airport, active: el.active }
          this.airportOptions.push(airport)
          if (this.value.includes(airport.id)) {
            objectifyOrganizationUserAirports.push(airport)
          }
        })

        this.localOrganizationUserAirports = objectifyOrganizationUserAirports
      })
    },
    confirmDeleteAlert(index) {
      this.$bvModal
        .msgBoxConfirm(`${this.$t('alert.delete_confirmation.message')}: ${this.localOrganizationUserAirports[index].name}`, {
          title: this.$t('alert.delete_confirmation.title'),
          size: 'sm',
          okVariant: 'primary',
          okTitle: this.$t('common.yes'),
          cancelTitle: this.$t('common.no'),
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(result => {
          if (result) {
            this.localOrganizationUserAirports.splice(index, 1)
          }
        })
    },
    repeatAgain() {
      if (this.airportOptionsFiltered.length) {
        this.localOrganizationUserAirports.push(this.airportOptionsFiltered[0])
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.airports-rows {
  max-height: 70vh;
  overflow-x: hidden;
  overflow-y: auto;
}
</style>
